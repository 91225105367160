import type { SystemStyleObject } from '@chakra-ui/react';

export const makeScrollbarStyles = (
    color: string,
    bgColor: string,
    hoverColor: string = 'primary.500'
): SystemStyleObject => {
    return {
        scrollbarColor: `${color} ${bgColor}`,
        '&::-webkit-scrollbar': {
            width: '12px',
            height: '12px',
            display: 'block',
            backgroundColor: bgColor,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: color,
            border: '2px solid',
            borderColor: bgColor,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: hoverColor,
        },

        '&.passive': {
            '&::-webkit-scrollbar, &::-webkit-scrollbar-thumb': {
                visibility: 'hidden',
            },
            '&:hover': {
                background: 'inherit', // safari fix
                '&::-webkit-scrollbar, &::-webkit-scrollbar-thumb': {
                    visibility: 'visible',
                },
            },
        },
    };
};
