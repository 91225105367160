import type {
    ButtonProps,
    InputGroupProps,
    InputProps,
} from '@chakra-ui/react';
import {
    Button,
    Icon,
    InputGroup,
    InputLeftElement,
    InputRightElement,
} from '@chakra-ui/react';
import * as React from 'react';
import {
    MagnifyingGlass as IconSearch,
    X as IconReset,
    KeyReturn as IconEnter,
} from '@phosphor-icons/react';
import { TrimmedInput } from './trimmed-input';

export interface SearchBoxProps extends Omit<InputGroupProps, 'onSubmit'> {
    initialValue?: string;
    onSubmit: (searchString: string) => unknown;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => unknown;
    isInstant?: boolean;
    placeholder?: string;
    boxHeight?: string;
    iconSpace?: InputProps['pl'];
    searchButtonProps?: ButtonProps;
}

export const SearchBox = ({
    initialValue,
    onSubmit,
    onKeyDown,
    isInstant = false,
    placeholder = 'search',
    boxHeight = '9',
    iconSpace = '40px',
    searchButtonProps,
    ...inputGroupProps
}: SearchBoxProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [value, setValue] = React.useState<string>(initialValue || '');

    // Listen to custom event: 'focusSearch' and focus on the input
    React.useEffect(() => {
        const focus = () => inputRef.current?.focus();
        document.addEventListener('focusSearch', focus);
        return () => {
            document.removeEventListener('focusSearch', focus);
        };
    }, []);

    const handleSubmit = () => onSubmit(value);
    const handleReset = () => {
        setValue('');
        onSubmit('');
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setValue(value);
        isInstant && onSubmit(value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSubmit();
        } else if (e.code === 'Escape') {
            handleReset();
        }

        onKeyDown && onKeyDown(e);
    };

    return (
        <InputGroup size="md" rounded="md" {...inputGroupProps}>
            <InputLeftElement
                h={boxHeight}
                pointerEvents="none"
                children={<Icon as={IconSearch} color="gray.500" />}
            />
            <TrimmedInput
                ref={inputRef}
                autoComplete="off"
                placeholder={placeholder}
                h={boxHeight}
                rounded="inherit"
                pl={iconSpace}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={value}
            />

            {value && (
                <InputRightElement
                    width={!isInstant ? '135px' : undefined}
                    padding="0"
                    h="100%"
                >
                    <Icon
                        size="2"
                        mr="3"
                        as={IconReset}
                        aria-label="reset"
                        bg="transparent"
                        opacity="0.6"
                        rounded="sm"
                        _hover={{ bg: 'blackAlpha.200' }}
                        onClick={handleReset}
                    />

                    {!isInstant && (
                        <Button
                            h="1.75rem"
                            size="sm"
                            onClick={handleSubmit}
                            {...searchButtonProps}
                        >
                            Search{' '}
                            <IconEnter
                                size="20px"
                                style={{
                                    opacity: '0.8',
                                    marginLeft: '0.5em',
                                    marginTop: '-3px',
                                }}
                            />
                        </Button>
                    )}
                </InputRightElement>
            )}
        </InputGroup>
    );
};

declare global {
    type FocusSearchEvent = CustomEvent<unknown>;

    interface DocumentEventMap {
        focusSearch: FocusSearchEvent;
    }
}
